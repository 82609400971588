@use "../common/summarize" as * ;

.contact {
    position: relative;
    &__inner {
        @include tab {
            max-width: 108rem;
            margin: 0 auto;
        }
    }
    &__title {
        @include mainTitle;
        @include pc {
            width: 100%;
        }
    }
    &__content {
        max-width: 88rem;
        margin: 0 auto;
    }
    &__text {
        padding: 0 2rem;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2;
        letter-spacing: 0.04em;
        margin-bottom: 4rem;
        @include tab {
            padding: 0;
            font-size: 1.8rem;
        }
    }
}

.contact-form {
    background-color: $white;
    padding: 6rem 2rem;
    @include tab {
        padding: 6rem;
        border-radius: 2rem;
    }
    &__item {
        &+& {
            margin-top: 3rem;
        }
    }
    &__label {
        position: relative;
        display: inline-block;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.4375;
        letter-spacing: 0.04em;
        margin-bottom: 1.4rem;
        &--required {
            &::after {
                display: inline-block;
                content: "必須";
                font-weight: 500;
                font-size: 1.2rem;
                line-height: 1;
                letter-spacing: 0.04em;
                background-color: $red;
                padding: .5em .8rem;
                border-radius: .6rem;
                margin-left: 1rem;
                color: $white;
            }
        }
    }
    &__input-text {
        width: 100%;
        border: .2rem solid $gray02;
        border-radius: .8rem;
        padding: 1.6rem 2rem;
        font-size: 1.6rem;
        @include tab {
            max-width: 46rem;
        }
    }
    &__input-textarea {
        width: 100%;
        border: .2rem solid $gray02;
        border-radius: .8rem;
        padding: 1.6rem 2rem;
        font-size: 1.6rem;
        height: 27.5rem;
    }
    &__radio {
        position: relative;
        &+& {
            margin-top: 1.4rem;
        }
    }
    &__radio-input {
        position: absolute;
        pointer-events: none;
        opacity: 0;
        width: 1px;
        height: 1px;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        &[type="radio"]:checked + label::before {
            border: .1rem solid $blue;
        }
        &[type="radio"]:checked + label::after {
            background: $blue;
        }
    }
    &__radio-label {
        position: relative;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.4375;
        letter-spacing: 0.04em;
        display: flex;
        align-items: center;
        &::before {
            content: '';
            display: block;
            width: 2.6rem;
            height: 2.6rem;
            background: $white;
            border-radius: 10rem;
            border: .1rem solid $gray02;
            margin-right: 1rem;
        }
        &::after {
            position: absolute;
            content: '';
            width: 1.2rem;
            height: 1.2rem;
            background: $white;
            top: 50%;
            left: 0.7rem;
            transform: translateY(-50%);
            border-radius: 10rem;
        }
    }
    &__confirm-btn {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        width: 29.5rem;
        height: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        background-color: $gray02;
        box-shadow: 0 0 0 rgba(25, 102, 194, 0.2);
        border-radius: 10rem;
        margin: 5rem auto 0;
        pointer-events: none;
        &.active {
            box-shadow: .6rem .6rem .8rem rgba(25, 102, 194, 0.2);
            background-color: $blue;
            pointer-events: all;
        }
    }
    &__inner {
        display: block;
        &--hidden {
            display: none;
        }
    }
    &__confirm {
        display: block;
        &--hidden {
            display: none;
        }
    }
    &__confirm-item {
        padding: 2rem 0;
        border-bottom: .1rem solid $gray02;
        &:first-of-type {
            border-top: .1rem solid $gray02;
        }
        @include tab {
            display: flex;
        }
    }
    &__confirm-title {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.43;
        letter-spacing: 0.04em;
        margin-bottom: 0.6rem;
        @include tab {
            width: 18rem;
            min-width: 18rem;
            margin-right: 2rem;
            font-size: 1.6rem;
        }
    }
    &__confirm-text {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.75;
        letter-spacing: 0.04em;
    }
    &__complete-btn {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        width: 29.5rem;
        height: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        border-radius: 10rem;
        margin: 5rem auto 0;
        box-shadow: .6rem .6rem .8rem rgba(25, 102, 194, 0.2);
        background-color: $blue;
    }
    &__back-btn {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        width: 19.3rem;
        height: 4.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10rem;
        margin: 2.5rem auto 0;
        box-shadow: .6rem .6rem .8rem rgba(25, 102, 194, 0.2);
        background-color: $white;
        border: .1rem solid $gray02;
    }
    &__complete-title {
        text-align: center;
        font-weight: 700;
        font-size: 2.6rem;
        line-height: 1.5;
        letter-spacing: 0.04em;
        margin-bottom: 4rem;
        @include tab {
            font-size: 2.8rem;
        }
    }
    &__complete-text {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.7;
        letter-spacing: 0.06em;
        &+& {
            margin-top: 2rem;
        }
    }
    &__complete-btn {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        width: 31rem;
        height: 6.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        border-radius: 10rem;
        margin: 5rem auto 0;
        box-shadow: .6rem .6rem .8rem rgba(16, 180, 121, 0.15);
        background-color: $green;
    }
}

