@use "../common/summarize" as * ;

.other-header {
    padding: 1rem 0;
    @include tab {
        padding: 2.5rem 0;
    }
    &__inner {
        padding: 0 1rem;
        @include tab {
            padding: 0 2.5rem;
        }
    }
    &__logo {
        display: flex;
        align-items: center;
        img {
            display: block;
            width: 5.2rem;
            height: auto;
            margin-right: 1.1rem;
        }
    }
    &__logo-text {
        font-family: $fontJpMaru;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 0.06em;
        margin-bottom: 0.6rem;
    }
    &__logo-name {
        font-family: $fontJpMaru;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.3;
        color: $blue;
    }
}