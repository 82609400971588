@use "../common/summarize" as * ;

.recruit-footer {
    position: relative;
    background: $gradationFooter;
    padding: 5rem 0 3rem;
    color: $white;
    @include tab {
        padding: 7rem 0 2rem;
    }
    &__inner {
        @include tab {
            padding: 0 3rem;
        }
    }
    &__content {
        padding: 0 3.2rem;
        margin-bottom: 7.6rem;
        @include tab {
            display: flex;
            justify-content: space-between;
            align-items: start;
            padding: 0;
            margin-bottom: 8.3rem;
        }
    }
    &__content-inner {
        @include tab {
            display: flex;
        }
    }
    &__content-logo {
        display: none;
        @include tab {
            display: block;
            width: 11rem;
            margin-right: 3.4rem;
        }
    }
    &__name {
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 1.6;
        margin-bottom: 1rem;
        font-family: $fontJpMaru;
    }
    &__address {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.8;
        letter-spacing: 0.04em;
        font-style: normal;
        margin-bottom: 3.4rem;
    }
    &__address-annotation {
        font-weight: 400;
        font-size: 1.2rem;
    }
    &__sns {
        display: flex;
        align-items: center;
        justify-content: center;
        @include pc {
            padding-right: 5rem;
        }
    }
    &__sns-text {
        position: relative;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.6;
        letter-spacing: 0.04em;
        font-family: $fontJpMaru;
        margin-right: 11.3rem;
        &::before {
            content: '';
            position: absolute;
            inset: 50% -2rem auto auto;
            transform: translate(100%, -50%);
            width: 6.5rem;
            height: 0.1rem;
            background-color: $white;
        }
    }
    &__sns-list {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__sns-item {
        margin-right: 2.9rem;
        &:last-of-type {
            margin-right: 0;
        }
    }
    &__sns-link {
        display: block;
        width: 3.1rem;
    }
    &__under {
        padding: 0 2.5rem;
        @include tab {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;
            padding: 0;
        }
    }
    &__privacy {
        text-align: center;
        margin-bottom: 1.5rem;
        @include tab {
            text-align: left;
            margin-bottom: 0;
        }
        a {
            display: inline-block;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: calc(20 / 14 * 100%);
        }
    }
    &__copy {
        text-align: center;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.4;
        letter-spacing: 0.04em;
        @include tab {
            font-size: 1.3rem;
            text-align: left;
        }
    }
}

.recruit-contact {
    background-color: $blue;
    color: $white;
    padding: 4rem 0 5rem;
    @include tab {
        padding: 8rem;
    }
    &__inner {
        @include inner(20);
        @include pc {
            max-width: 100rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    &__content {
        @include pc {
            width: calc(657 / 1180 * 100%);
        }
    }
    &__title {
        font-weight: 700;
        font-size: 2rem;
        line-height: 1.6;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        margin-bottom: 1.6rem;
        @include pc {
            font-size: 2.4rem;
            margin-bottom: 1.4rem;
        }
    }
    &__text {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.75;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        margin-bottom: 3.2rem;
        @include pc {
            font-size: 1.8rem;
            margin-bottom: 0;
        }
    }
    &__link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border: .1rem solid $white;
        border-radius: 1rem;
        padding: 2.4rem 0;
        width: 100%;
        font-weight: 700;
        font-size: 1.6rem;
        font-family: $fontJpMaru;
        letter-spacing: 0.02em;
        @include pc {
            font-size: 2rem;
            width: calc(480 / 1180 * 100%);
            min-width: 46rem;
        }
        &--arrow {
            width: 2.2rem;
            margin-left: 1.4rem;
            @include tab {
                width: 2.4rem;
                margin-left: 0;
                position: absolute;
                right: 2.2rem;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &--mail {
            width: 2.4rem;
            margin-right: 1.4rem;
            @include tab {
                width: 2.6rem;
                margin-right: 0;
                position: absolute;
                left: 3.3rem;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}