@use "var" as * ;
@mixin pc {
	@media (min-width: 1024px) {
		@content;
	}
}
@mixin tab {
	@media (min-width: 768px) {
		@content;
	}
}
@mixin horizontal {
	@media all and (orientation:landscape) {
		@content;
	}
}
@mixin vertical {
	@media all and (orientation:portrait) {
		@content;
	}
}


@mixin inner($padding: 10) {
	$padding: 375 - ($padding * 2);
	width: calc($padding / 375 * 100%);
	margin: 0 auto;
}

@mixin gradientText {
	display: inline-block;
	background: $gradation01;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	color: $green;
}

@mixin btn($width: 29.5rem, $height: 6rem, $fontSize: 1rem) {
	width: $width;
	height: $height;
	font-size: $fontSize;

	position: relative;
	background-color: $green;
	box-shadow: .6rem .6rem .8rem rgba(16, 180, 121, 0.25);
	border-radius: 10rem;
	color: $white;
	font-weight: 700;
	font-family: $fontJpMaru;
	display: flex;
	justify-content: center;
	align-items: center;
	@include tab {
		width: 31rem;
		height: 6.6rem;
	}
	&::before {
		content: '';
		position: absolute;
		inset: 50% 1.7rem auto auto;
		transform: translateY(-50%);
		width: 1.8rem;
		height: 1.8rem;
		background-image: url(../images/common/icon_arrow_g.svg);
		background-position: center center;
		background-repeat: no-repeat;
		@include tab {
			width: 2.4rem;
			height: 2.4rem;
		}
	}
}

@mixin scrollBarRemove {
	-ms-overflow-style: none;
	scrollbar-width: none;
    &::-webkit-scrollbar{
        display:none;
    }
}

@mixin mainTitle {
	font-weight: 700;
	font-size: 2rem;
	font-family: $fontJpMaru;
	color: $white;
	letter-spacing: 0.04em;
	width: 100%;
	height: 8rem;
	padding-left: 2rem;
	display: flex;
	align-items: center;
	background: $gradation01;
	@include pc {
		font-size: 3rem;
		line-height: 1.8;
		height: 11rem;
		width: 96%;
		padding-left: 4.6rem;
		border-radius: 2rem;
		margin-top: 2rem;
	}
}

@mixin recruitTitle {
	font-weight: 700;
	font-size: 2.4rem;
	line-height: 1.6;
	letter-spacing: 0.02em;
	text-align: center;
	margin-bottom: 3rem;
	font-family: $fontJpMaru;
	@include tab {
		font-size: 3.6rem;
		margin-bottom: 5.4rem;
	}
	span {
		position: relative;
		display: inline-block;
		font-size: 1.4rem;
		margin-bottom: 0.6rem;
		@include tab {
			font-size: 2.2rem;
			margin-bottom: 1.2rem;
		}
		&::before,
		&::after {
			content: '';
			position: absolute;
			width: 0.1rem;
			height: 100%;
			background-color: $black02;
			top: 50%;
		}
		&::before {
			left: -1.5rem;
			transform: translateY(-50%) rotate(-30deg);
		}
		&::after {
			right: -1.5rem;
			transform: translateY(-50%) rotate(30deg);
		}
	}
}