@use "../common/summarize" as * ;

.news-label {
    @include scrollBarRemove;
    width: 100%;
    padding: 0 2rem;
    display: flex;
    overflow-x: scroll;
    margin-bottom: 3rem;
    @include tab {
        margin-bottom: 4rem;
        padding: 0;
    }
    &__item {
        margin-right: 0.6rem;
    }
    &__link {
        display: inline-block;
        white-space: nowrap;
        word-break: keep-all;
        padding: 0.7rem 1.4rem;
        background-color: $white;
        border: .1rem solid $green;
        border-radius: 6rem;
        color: $green;
        font-weight: 500;
        font-size: 1.4rem;
        &--active {
            background-color: $green;
            color: $white;
        }
    }
}

.news {
    position: relative;
    &__inner {
        @include tab {
            padding: 0 2rem;
        }
        @include pc {
            padding: 0 5rem 0 0;
        }
    }
    &__title {
        @include mainTitle;
    }
    &__list {
        margin-bottom: 4rem;
        @include tab {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto;
            grid-column-gap: 2rem;
            grid-row-gap: 2.6rem;
        }
    }
    &__item-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 1.5rem;
        background-color: $white;
        border-bottom: .2rem solid $gray03;
        overflow: hidden;
        height: 100%;
        box-shadow: 1rem 1rem 2rem rgba(80, 74, 68, 0.05);
        @include tab {
            display: block;
            padding: 0;
            border-bottom: none;
            border-radius: 1rem;
        }
    }
    &__item-img {
        border-radius: 1rem;
        overflow: hidden;
        width: calc(100 / 345 * 100%);
        @include tab {
            border-radius: 0;
            width: 100%;
        }
    }
    &__item-content {
        width: calc(220 / 345 * 100%);
        @include tab {
            padding: 2rem;
            width: 100%;
        }
    }
    &__item-title {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: 0.06em;
        margin-bottom: 0.6rem;
        color: $blue;
        @include tab {
            font-size: 1.8rem;
            margin-bottom: 1.5rem;
        }
    }
    &__item-text {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5;
        letter-spacing: 0.04em;
        margin-bottom: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @include tab {
            font-size: 1.4rem;
            margin-bottom: 1.5rem;
        }
    }
    &__item-flex {
        display: flex;
        align-items: center;
    }
    &__item-label {
        display: inline-block;
        padding: 0.4rem .8rem;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5;
        color: $green;
        letter-spacing: 0.04em;
        border: .1rem solid $green;
        border-radius: 6rem;
        margin-right: 1rem;
        @include tab {
            font-size: 1.2rem;
            margin-right: 1.4rem;
        }
    }
    &__item-time {
        display: inline-block;
        font-weight: 500;
        font-size: 1.1rem;
        font-family: $fontEn;
        color: $gray;
        letter-spacing: 0.04em;
        @include tab {
            font-size: 1.2rem;
        }
    }
}

.news-detail {
    background-color: $white;
    padding: 5rem 0 7rem;
    @include pc {
        padding: 8rem 0 9rem;
        max-width: 91.8rem;
        width: 90%;
        margin: 0 auto;
        border-radius: 2rem;
    }
    &__inner {
        @include inner(20);
        @include pc {
            width: 68.4%;
        }
    }
    &__title {
        font-weight: 700;
        font-size: 2rem;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        margin-bottom: 1.5rem;
        @include pc {
            font-size: 2.8em;
            margin-bottom: 1.9rem;
        }
    }
    &__flex {
        display: flex;
        align-items: center;
        margin-bottom: 3.8rem;
        @include pc {
            margin-bottom: 4rem;
        }
    }
    &__label {
        display: inline-block;
        font-weight: 500;
        font-size: 1rem;
        letter-spacing: 0.04em;
        padding: 0.4rem 0.8rem;
        color: $green;
        border: .1rem solid $green;
        border-radius: 6rem;
        margin-right: 1.2rem;
        @include pc {
            font-size: 1.2em;
            padding: 0.4rem 1rem;
        }
    }
    &__time {
        font-weight: 500;
        font-size: 1.1rem;
        font-family: $fontEn;
        color: $gray;
        letter-spacing: 0.04em;
        @include pc {
            font-size: 1.2em;
        }
    }
    // &__subtitle {
    //     font-weight: 700;
    //     font-size: 1.8rem;
    //     line-height: 1.65;
    //     letter-spacing: 0.04em;
    // }
    // &__text {
    //     font-weight: 400;
    //     font-size: 1.6rem;
    //     line-height: 1.8;
    //     letter-spacing: 0.04em;
    //     @include pc {
    //         font-size: 2rem;
    //         line-height: 1.8;
    //     }
    // }
    &__content {
        > h3 {
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 1.65;
            letter-spacing: 0.04em;
            margin-bottom: 2rem;
            @include pc {
                font-size: 2rem;
                line-height: 1.8;
            }
        }
        > p {
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 1.8;
            letter-spacing: 0.04em;
            margin-bottom: 2rem;
        }
        li {
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 1.8;
            letter-spacing: 0.04em;
            list-style: decimal;
            list-style-position: inside;
        }
    }
    &__img-wrap {
        display: flex;
        justify-content: space-between;
        > div {
            width: calc(50% - .9rem);
        }
    }
    &__img {
        margin-bottom: 1.7rem;
    }
    &__link-content {
        @include inner(10);
        @include pc {
            max-width: 91.8rem;
            width: 90%;
        }
    }
    &__link-list {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
        @include pc {
            margin-top: 4rem;
        }
    }
    &__link-item {
        width: calc(50% - .7rem);
        @include pc {
            width: 28rem;
        }
        a {
            position: relative;
            display: block;
            border-radius: 1rem;
            width: 100%;
            background-color: $white;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.4;
            letter-spacing: 0.1em;
            box-shadow: .6rem .6rem .8rem rgba(80, 74, 68, 0.05);
            @include pc {
                font-size: 1.4rem;
                line-height: 1.5;
            }
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                width: .8rem;
                height: .8rem;
            }
        }
        &:nth-of-type(1) {
            a {
                padding: 1.2rem 1rem 1.2rem 2.7rem;
                @include pc {
                    padding: 1.7rem 2.6rem 1.7rem 4.4rem;
                }
                &::before {
                    left: 1rem;
                    border-bottom: .2rem solid $black02;
                    border-left: .2rem solid $black02;
                    @include pc {
                        left: 1.8rem;
                    }
                }
            }
        }
        &:nth-of-type(2) {
            a {
                padding: 1.2rem 2.7rem 1.2rem 1rem;
                @include pc {
                    padding: 1.7rem 4.4rem 1.7rem 2.6rem;
                }
                &::before {
                    right: 1rem;
                    border-top: .2rem solid $black02;
                    border-right: .2rem solid $black02;
                    @include pc {
                        right: 1.8rem;
                    }
                }
            }
        }
        span {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            @include pc {
                -webkit-line-clamp: 2;
            }
        }
    }
}