@use "../common/summarize" as * ;

.recruit {
    position: relative;
}

.recruit-news  {
    @include inner();
    background-color: $white;
    border-radius: 1rem;
    padding: 2rem;
    margin: 2rem auto 0;
    @include tab {
        max-width: 111.2rem;
    }
    @include pc {
        position: relative;
        transform: translateY(-50%);
        width: calc(100% - 3rem);
        padding: 3rem;
        border-radius: 10rem;
        display: flex;
        align-items: center;
        margin: 0 auto;
    }
    &__text {
        position: relative;
        font-weight: 700;
        font-size: 1.4rem;
        letter-spacing: 0.08em;
        font-family: $fontJpMaru;
        padding-left: 2.7rem;
        margin-bottom: 1.4rem;
        @include pc {
            font-size: 1.8rem;
            padding-left: 4rem;
            margin-bottom: 0;
            margin-right: 4.1rem;
            padding-right: 4.1rem;
            border-right: .1rem solid $gray02;
        }
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 2rem;
            height: 2rem;
            background-image: url(../images/common/icon_update.svg);
            background-position: center center;
            background-repeat: no-repeat;
            @include pc {
                width: 2.8rem;
                height: 2.8rem;
            }
        }
    }
    &__time {
        font-weight: 500;
        font-size: 1.2rem;
        letter-spacing: 0.08em;
        font-family: $fontEn;
        color: $gray;
        margin-right: 1.3rem;
        @include pc {
            font-size: 1.4rem;
            margin-right: 2rem;
        }
    }
    &__link {
        display: inline-block;
        font-weight: 400;
        font-size: 1.4rem;
        letter-spacing: .1rem;
        color: $blue;
        text-decoration: underline;
        @include pc {
            font-size: 1.6rem;
        }
    }
}

.recruit-reason {
    padding: 7rem 0;
    &__inner {
        @include inner(20);
        max-width: 118rem;
    }
    &__title {
        @include recruitTitle;
    }
    &__box {
        @include tab {
            display: flex;
            justify-content: space-between;
        }
    }
    &__img-list {
        margin-bottom: 4rem;
        @include tab {
            width: calc(580 / 1180 * 100%);
            margin-bottom: 0;
        }
    }
    &__content {
        @include tab {
            width: calc(500 / 1180 * 100%);
        }
    }
    &__subtitle {
        font-weight: 700;
        font-size: 2rem;
        line-height: 1.7;
        text-align: center;
        font-family: $fontJpMaru;
        letter-spacing: 0.02em;
        margin-bottom: 2rem;
        @include pc{
            text-align: left;
        }
        @include tab {
            font-size: 2.6rem;
            margin-bottom: 3.6rem;
        }
    }
    &__text {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.8;
        letter-spacing: 0.04em;
        @include tab {
            font-size: 1.8rem;
            line-height: 2;
            letter-spacing: 0.02em;
        }
        &+& {
            margin-top: 2.5rem;
            @include tab {
                margin-top: 3.6rem;
            }
        }
    }
    &__popular {
        background-color: $white;
        padding: 3.5rem 2rem;
        border-radius: 1rem;
        margin-top: 3rem;
        @include tab {
            padding: 5rem 6rem;
            margin-top: 6rem;
        }
    }
    &__popular-title {
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 1;
        font-family: $fontJpMaru;
        letter-spacing: 0.02em;
        text-align: center;
        margin-bottom: 2.5rem;
        @include tab {
            font-size: 2.8rem;
            margin-bottom: 3rem;
        }
        &--blue {
            font-size: 2.6rem;
            color: $blue;
            @include tab {
                font-size: 3.2rem;
            }
        }
        &--small {
            @include tab {
                font-size: 2.2rem;
            }
        }
    }
    &__popular-list {
        counter-reset: number 0;
        @include tab {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__popular-item {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.3;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        text-indent: -2.6rem;
        padding-left: 2.6rem;
        @include tab {
            width: 50%;
            font-size: 1.8rem;
        }
        &+& {
            margin-top: 1.4rem;
        }
        &:nth-of-type(2) {
            @include tab {
                margin-top: 0;
            }
        }
        &::before {
            counter-increment: number 1;
            content: counter(number) ".";
            font-weight: 700;
            font-size: 2rem;
            line-height: 1;
            font-family: $fontJpMaru;
            letter-spacing: 0.04em;
            color: $blue;
            padding-right: 1rem;
        }
    }
}

.recruit-interview {
    background-color: $white;
    padding: 6rem 0;
    overflow: hidden;
    @include tab {
        padding: 8rem 0;
    }
    &__title {
        @include recruitTitle;
    }
    &__list {
        @include inner(40);
        margin-bottom: 5rem;
        @include tab {
            @include inner(20);
            margin-bottom: 9rem;
        }
        @include pc {
            max-width: 118rem;
            width: 100%;
        }
    }
    &__list-inner {
        @include tab {
            // justify-content: space-between;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto;
            grid-column-gap: 2rem;
            grid-row-gap: 0;
        }
    }
    &__item {
        padding: 0 1.5rem;
        @include tab {
            padding: 0;
            // width: calc((100% - 4rem) / 3);
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
    &__link {
        display: block;
        border-radius: 2rem;
        box-shadow: 1rem 1rem 2rem rgba(80, 74, 68, 0.05);
        background-color: $white;
        overflow: hidden;
    }
    &__img {
        height: 24.5rem;
        @include tab {
            height: 27rem;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__link-inner {
        padding: 2rem 1.7rem;
    }
    &__name {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: 0.06em;
        margin-bottom: 1rem;
        color: $blue;
        @include tab {
            font-size: 1.8rem;
        }
    }
    &__content-item {
        display: flex;
        align-items: start;
        margin-bottom: .6rem;
    }
    &__content-title {
        display: inline-block;
        background: $gray04;
        border-radius: 10rem;
        font-weight: 400;
        font-size: 1.3rem;
        letter-spacing: 0.04em;
        padding: 0.4rem 1rem;
        margin-right: 0.6rem;
        white-space: nowrap;
        word-break: keep-all;
    }
    &__content-text {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.5;
        letter-spacing: 0.04em;
    }
    &__index {
        a {
            @include btn(29.5rem, 6rem, 1.6rem);
            margin: 0 auto;
        }
    }
}

.recruit-oneday {
    padding: 6rem 0;
    @include tab {
        padding: 8rem 0;
    }
    &__inner {
        @include inner(10);
        max-width: 78rem;
        aspect-ratio: 16 / 9;
        iframe {
            width: 100%;
            height: 100%;
        }
    }
    &__title {
        @include recruitTitle;
    }
}

.recruit-intro {
    background-color: $white;
    padding: 7rem 0;
    @include tab {
        padding: 11rem 0;
    }
    &__title {
        @include recruitTitle;
    }
    &__content {
        @include inner(20);
        max-width: 118rem;
        padding-bottom: 6rem;
        margin-bottom: 6rem;
        border-bottom: .1rem solid $gray02;
        @include tab {
            display: flex;
            justify-content: space-between;
            padding-bottom: 7.7rem;
            margin-bottom: 7.7rem;
        }
        &:nth-of-type(even) {
            flex-direction: row-reverse;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &__content-img {
        margin-bottom: 2rem;
        @include tab {
            margin-bottom: 0;
            width: calc(510 / 1180 * 100%);
        }
    }
    &__content-inner {
        @include tab {
            width: calc(600 / 1180 * 100%);
        }
    }
    &__name {
        font-weight: 700;
        font-size: 2rem;
        line-height: 1.6;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        margin-bottom: 1.5rem;
        @include tab {
            font-size: 2.8rem;
            margin-bottom: 1.3rem;
        }
    }
    &__text {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.85;
        letter-spacing: 0.04em;
        margin-bottom: 3.4rem;
        @include tab {
            font-size: 1.6rem;
            margin-bottom: 2.7rem;
        }
    }
    &__content-list {
        border: .1rem solid $gray02;
        border-radius: 1rem;
        overflow: hidden;
        display: flex;
        margin-bottom: 3.4rem;
    }
    &__content-item {
        width: 50%;
        &:first-of-type {
            border-right: .1rem solid $gray02;
        }
    }
    &__content-title {
        background: $blue;
        color: $white;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.8;
        text-align: center;
        letter-spacing: 0.04em;
        padding: 0.8rem;
        text-align: center;
        border-bottom: .1rem solid $gray02;
        @include tab {
            font-size: 1.6rem;
        }
    }
    &__content-text {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.8;
        text-align: center;
        letter-spacing: 0.04em;
        padding: 0.8rem;
        text-align: center;
        @include tab {
            font-size: 1.6rem;
        }
    }
    &__link {
        text-align: right;
        a {
            position: relative;
            display: inline-block;
            padding-right: 2.8rem;
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 1.8;
            letter-spacing: 0.04em;
            @include tab {
                font-size: 1.6rem;
                padding-right: 4rem;
            }
            &::before {
                content: '';
                width: 1.8rem;
                height: 1.8rem;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                background: url('../images/common/icon_arrow_gw.svg') no-repeat center center;
                background-size: contain;
                @include tab {
                    width: 3rem;
                    height: 3rem;
                }
            }
        }
    }
}

.recruit-activity {
    background-color: $white;
    padding: 0 0 7rem;
    @include tab {
        padding: 0 0 11rem;
    }
    &__title {
        @include recruitTitle;
    }
    &__content {
        @include inner(20);
        max-width: 118rem;
        &+& {
            margin-top: 4rem;
            @include tab {
                margin-top: 6rem;
            }
        }
        &:last-of-type {
            margin-bottom: 7rem;
            @include tab {
                margin-bottom: 12rem;
            }
        }
    }
    &__subtitle {
        position: relative;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 1.6;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        margin-bottom: 1.5rem;
        padding-left: 1.4rem;
        @include tab {
            font-size: 2rem;
            margin-bottom: 1.1rem;
        }
        &::before {
            content: '';
            width: 0.3rem;
            height: 1.9rem;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-color: $blue;
            border-radius: 1rem;
        }
    }
    &__text {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.85;
        letter-spacing: 0.04em;
        @include tab {
            font-size: 1.6rem;
            line-height: 1.8;
        }
        &+& {
            margin-top: 2.6rem;
            @include tab {
                margin-top: 2.9rem;
            }
        }
    }
    &__insta-wrap {
        @include inner(15);
        max-width: 118rem;
        iframe {
            width: 100%;
            height: 38rem;
            @include tab {
                height: 58rem;
            }
        }
    }
    &__insta {
        display: flex;
        align-items: center;
        margin-bottom: 2.4rem;
        @include tab {
            margin-bottom: 3.7rem;
        }
    }
    &__insta-icon {
        width: 5rem;
        margin-right: 1rem;
        @include tab {
            width: 6rem;
            margin-right: 1rem;
        }
    }
    &__insta-text {
        font-weight: 500;
        font-size: 1.8rem;
        font-family: $fontJpMaru;
        letter-spacing: 0.02em;
    }
    &__insta-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        grid-column-gap: .8rem;
        grid-row-gap: .8rem;
        @include tab {
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 2rem;
            grid-row-gap: 2rem;
        }
    }
    &__insta-item {
        &:last-of-type {
            @include tab {
                display: none;
            }
        }
    }
}

.recruit-description {
    padding: 6rem 0;
    @include tab {
        padding: 10rem 0;
    }
    &__title {
        @include recruitTitle;
    }
    &__text {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.6;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        margin-bottom: 3rem;
        text-align: center;
        @include tab {
            font-size: 2rem;
            margin-bottom: 4rem;
        }
    }
    &__btn-list {
        padding: 0 1rem;
        display: flex;
        align-items: end;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        @include tab {
            padding: 0;
            max-width: 118rem;
            margin: 0 auto;
            justify-content: space-between;
        }
    }
    &__btn-item {
        margin-right: .6rem;
        @include tab {
            margin-right: 0;
            width: calc(275 / 1180 * 100%);
        }
        &--active {
            width: auto;
            @include tab {
                width: calc(330 / 1180 * 100%);
            }
        }
    }
    &__btn-link {
        background-color: $blue;
        border-radius: 1rem 1rem 0 0;
        color: $white;
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 1.4;
        font-family: $fontJpMaru;
        text-align: center;
        letter-spacing: 0.04em;
        height: 5.6rem;
        width: 12rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include tab {
            font-size: 2rem;
            height: 7rem;
            width: 100%;
        }
        span {
            font-weight: 500;
            font-size: 1rem;
            @include tab {
                font-size: 1.3rem;
            }
        }
        &--active {
            height: 8rem;
            background-color: $white;
            color: $black02;
            @include tab {
                height: 9rem;
            }
        }
    }
    &__list {
        background-color: $white;
        padding: 4rem 0 6rem;
        @include tab {
            max-width: 118rem;
            margin: 0 auto;
        }
    }
    &__item {
        @include inner(25);
        display: none;
        &--active {
            display: block;
        }
    }
    &__content-title {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: 0.04em;
        margin-bottom: 1.5rem;
    }
    &__content-list {
        border: .1rem solid $gray02;
        &+ .recruit-description__content-title {
            margin-top: 4rem;
            @include pc {
                margin-top: 5rem;
            }
        }
    }
    &__content-item {
        display: flex;
        border-bottom: .1rem solid $gray02;
        &:last-of-type {
            border-bottom: none;
        }
    }
    &__content-item-title {
        padding: 1.5rem;
        width: calc(105 / 345 * 100%);
        background-color: #F8FAFD;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.8;
        letter-spacing: 0.04em;
        @include pc {
            padding: 1.5rem 2.5rem;
            width: 15rem;
            font-size: 1.6rem;
        }
    }
    &__content-item-text {
        padding: 1.5rem;
        width: calc(240 / 345 * 100%);
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.5;
        letter-spacing: 0.04em;
        @include pc {
            padding: 1.5rem 2rem;
            width: calc(100% - 15rem);
            font-size: 1.6rem;
        }
    }
}