@use "../common/summarize" as * ;

.policy {
    position: relative;
    &__inner {
        @include tab {
            max-width: 108rem;
            margin: 0 auto;
        }
    }
    &__title {
        @include mainTitle;
        @include pc {
            width: 100%;
        }
    }
    &__content {
        background-color: $white;
        padding: 5rem 2rem;
        @include tab {
            margin: 0 auto;
            width: 90%;
            max-width: 89.4rem;
            padding: 8rem 9rem;
            border-radius: 2rem;
        }
    }
    &__box {
        &+& {
            margin-top: 3rem;
        }
    }
    &__subtitle {
        position: relative;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 1.6;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        padding-left: 1.4rem;
        margin-bottom: 1.5rem;
        @include tab {
            font-size: 2rem;
        }
        &::before {
            position: absolute;
            content: "";
            width: 0.3rem;
            height: 1.9rem;
            background-color: $blue;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            border-radius: 10rem;
            @include tab {
                height: 2.3rem;
            }
        }
    }
    &__text {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.8;
        letter-spacing: 0.04em;
        margin-bottom: 1rem;
        &--first {
            margin-bottom: 4rem;
        }
    }
    &__list {
        margin-bottom: 1rem;
    }
    &__item {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.8;
        letter-spacing: 0.04em;
        list-style: inside;
    }
}


