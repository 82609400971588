@use "../common/summarize" as * ;

.access {
    position: relative;
    &__title {
        @include mainTitle;
    }
    &__box {
        background-color: $white;
        padding: 5rem 0;
        @include tab {
            padding: 8rem 0;
            border-radius: 2rem;
        }
        @include pc {
            width: calc(100% - 5rem);
        }
    }
    &__inner {
        padding: 0 1rem;
        @include tab {
            padding: 0 2rem;
        }
        @include pc {
            padding: 0 7rem;
        }
    }
    &__text {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.8;
        letter-spacing: 0.02em;
        margin-bottom: 4rem;
        padding: 0 1rem;
        @include tab {
            font-size: 1.6rem;
            margin-bottom: 6rem;
        }
    }
    &__map {
        margin-bottom: 4rem;
        @include tab {
            margin-bottom: 6rem;
        }
        iframe {
            width: 100%;
            height: 38rem;
        }
    }
    &__content {
        padding: 0 1rem;
        @include tab {
            display: flex;
            justify-content: space-between;
        }
    }
    &__content-inner {
        &:nth-of-type(1) {
            @include tab {
                width: calc(470 / 962 * 100%);
            }
        }
        &:nth-of-type(2) {
            @include tab {
                width: calc(440 / 962 * 100%);
            }
        }
        &+& {
            margin-top: 4rem;
            @include tab {
                margin-top: 0;
            }
        }
    }
    &__content-section {
        &+& {
            margin-top: 3rem;
        }
    }
    &__content-title {
        font-weight: 700;
        font-size: 1.7rem;
        line-height: 1;
        letter-spacing: 0.02em;
        color: $blue02;
        margin-bottom: 1.6rem;
        @include tab {
            font-size: 2rem;
        }
        &--small {
            color: $green;
            margin-top: 2.8rem;
            font-size: 1.6rem;
            @include tab {
                font-size: 1.6rem;
            }
        }
    }
    &__content-address {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.75;
        font-style: normal;
        letter-spacing: 0.02em;
        a{
            color: $blue;
            text-decoration: underline;
        }
        & + .access__content-address-text {
            margin-top: 1.8rem;
        }
        @include pc {
            font-size: 1.6rem;
        }
    }
    &__content-address-text {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1;
        letter-spacing: 0.02em;
        margin-bottom: 0.6rem;
    }
    &__content-address-annotation {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.6;
        letter-spacing: 0.02em;
        &--red {
            color: $red;
        }
    }
    &__content-box {
        &+& {
            margin-top: 2.2rem;
        }
    }
}
