@use "../common/summarize" as * ;

.interview {
    position: relative;
    &__inner {
        @include tab {
            max-width: 108rem;
            margin: 0 auto;
        }
    }
    &__title {
        @include mainTitle;
        @include pc {
            width: 100%;
        }
    }
    &__list {
        @include inner(10);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        grid-column-gap: 1rem;
        grid-row-gap: 2rem;
        @include tab {
            width: 100%;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 3.6rem;
            grid-row-gap: 4rem;
        }
    }
    &__link {
        display: block;
        border-radius: 2rem;
        box-shadow: 1rem 1rem 2rem rgba(80, 74, 68, 0.05);
        background-color: $white;
        overflow: hidden;
    }
    &__img {
        height: 14.4rem;
        @include tab {
            height: 27rem;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__link-inner {
        padding: 1rem;
        @include tab {
            padding: 2rem 1.7rem;
        }
    }
    &__name {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: 0.06em;
        margin-bottom: .8rem;
        color: $blue;
        @include tab {
            font-size: 1.8rem;
            margin-bottom: 1rem;
        }
    }
    &__content-item {
        display: flex;
        align-items: start;
        margin-bottom: .4rem;
        @include tab {
            margin-bottom: .4rem;
        }
    }
    &__content-title {
        display: inline-block;
        background: $gray04;
        border-radius: 10rem;
        font-weight: 400;
        font-size: 1rem;
        letter-spacing: 0.04em;
        padding: .2rem .4rem;
        margin-right: 0.6rem;
        white-space: nowrap;
        word-break: keep-all;
        @include tab {
            font-size: 1.3rem;
            padding: 0.4rem 1rem;
        }
    }
    &__content-text {
        font-weight: 400;
        font-size: 1.1rem;
        line-height: 1.5;
        letter-spacing: 0.04em;
        @include tab {
            font-size: 1.4rem;
        }
    }
}
.interview-detail {
    background-color: $white;
    padding: 5rem 2rem;
    @include tab {
        margin: 0 auto;
        width: 90%;
        max-width: 89.4rem;
        padding: 8rem 9rem;
        border-radius: 2rem;
    }
    &__title {
        margin: 3rem 0 1.3rem;
        font-weight: 700;
        font-size: 2rem;
        line-height: 1.6;
        font-family: $fontJpMaru;
        letter-spacing: 0.02em;
        @include tab {
            margin: 4rem 0 2rem;
            font-size: 2.8rem;
        }
    }
    &__time {
        display: inline-block;
        position: relative;
        font-weight: 400;
        font-size: 1.2rem;
        letter-spacing: 0.04em;
        color: $gray;
        padding-left: 2rem;
        margin-bottom: 2rem;
        @include tab {
            font-size: 1.4rem;
        }
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 1.3rem;
            height: 1.3rem;
            background: url(../images/common/icon_time.svg) no-repeat center center;
            background-size: contain;
        }
    }
    &__list {
        padding-bottom: 4rem;
        margin-bottom: 5rem;
        border-bottom: .1rem solid $gray02;
    }
    &__item {
        display: flex;
        align-items: start;
        margin-bottom: .6rem;
    }
    &__item-title {
        display: inline-block;
        background: $gray04;
        border-radius: 10rem;
        font-weight: 400;
        font-size: 1.3rem;
        letter-spacing: 0.04em;
        padding: 0.4rem 1rem;
        margin-right: 0.6rem;
        white-space: nowrap;
        word-break: keep-all;
    }
    &__item-text {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.5;
        letter-spacing: 0.04em;
    }
    &__content {
        & > h3 {
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 1.75;
            letter-spacing: 0.04em;
            margin:6rem 0 2.1rem;
            @include tab {
                font-size: 1.8rem;
                margin-bottom: 3rem;
            }
            & + p {
                position: relative;
                text-indent: 8.5rem;
                &::before {
                    content: '';
                    position: absolute;
                    width: 7.7rem;
                    height: 0.1rem;
                    background-color: $gray02;
                    top: 1.5rem;
                    left: 0;
                }
            }
        }
        & > p {
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 1.8;
            letter-spacing: 0.04em;
            margin-bottom: 2.1rem;
            @include tab {
                font-size: 1.6rem;
                margin-bottom: 3rem;
                letter-spacing: .12rem;
            }
            &+& {
                margin-top: 3rem;
            }
        }
        img {
            width: auto;
        }
    }
    &__subtitle {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.75;
        letter-spacing: 0.04em;
        margin-bottom: 2.1rem;
        @include tab {
            font-size: 1.8rem;
            margin-bottom: 3rem;
        }
        & + p {
            position: relative;
            text-indent: 8.5rem;
            &::before {
                content: '';
                position: absolute;
                width: 7.7rem;
                height: 0.1rem;
                background-color: $gray02;
                top: 1.5rem;
                left: 0;
            }
        }
    }
    &__text {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.8;
        letter-spacing: 0.04em;
        margin-bottom: 2.1rem;
        @include tab {
            font-size: 1.6rem;
            margin-bottom: 3rem;
            letter-spacing: .12rem;
        }
        &+& {
            margin-top: 3rem;
        }
    }
    &__work {
        margin: 6rem 0;
    }
    &__work-title {
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 1.75;
        text-align: center;
        letter-spacing: 0.04em;
        margin-bottom: 3rem;
    }
    &__work-item {
        @include tab {
            display: flex;
            align-items: start;
        }
        &+& {
            margin-top: 3rem;
            @include tab {
                margin-top: 3.4rem;
            }
        }
    }
    &__work-time {
        display: inline-block;
        position: relative;
        padding-left: 2.4rem;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.75;
        letter-spacing: 0.04em;
        color: #898989;
        margin-bottom: 0.6rem;
        @include tab {
            padding-left: 3.4rem;
            font-size: 1.6rem;
            margin-bottom: 0;
            width: 16rem;
            min-width: 16rem;
        }
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 1.6rem;
            height: 1.6rem;
            background: url(../images/common/icon_clock.svg) no-repeat center center;
            background-size: contain;
            @include pc {
                width: 2rem;
                height: 2rem;
            }
            @include tab {
                width: 2.4rem;
                height: 2.4rem;
            }
        }
    }
    &__work-subtitle {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.75;
        letter-spacing: 0.04em;
        @include tab {
            font-size: 1.8rem;
        }
        & + .interview-detail__work-text {
            margin-top: 1rem;
            @include tab {
                margin-top: 0.6rem;
            }
        }
    }
    &__work-text {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.75;
        letter-spacing: 0.04em;
        @include tab {
            font-size: 1.6rem;
        }
    }
    &__last {
        @include tab {
            position: relative;
            padding-right: 27.5rem;
        }
        picture {
            margin-bottom: 3rem;
            @include tab {
                position: absolute;
                top: 0;
                right: 0;
                width: 24.3rem;
                margin-bottom: 0;
            }
            & + p {
                position: relative;
                text-indent: 8.5rem;
                &::before {
                    content: '';
                    position: absolute;
                    width: 7.7rem;
                    height: 0.1rem;
                    background-color: $gray02;
                    top: 1.5rem;
                    left: 0;
                }
            }
        }
    }
}

.interview-detail-btn {
    @include inner(10);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
    @include tab {
        max-width: 89.4rem;
    }
    &__link {
        position: relative;
        width: 16.4rem;
        height: 7rem;
        background: $white;
        box-shadow: .6rem .6rem .8rem rgba(80, 74, 68, 0.05);
        border-radius: 1rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 0.08em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include pc {
            font-size: 1.2rem;
            height: 8.6rem;
        }
        @include tab {
            width: 28rem;
            height: 7.5rem;
        }
        &::before {
            position: absolute;
            content: '';
            width: .6rem;
            height: .6rem;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
        }
        &--prev {
            padding-left: 2.8rem;
            @include pc {
                padding-left: 3.4rem;
            }
            @include tab {
                padding-left: 3.6rem;
            }
            &::before {
                left: 1.4rem;
                border-bottom: .2rem solid $black02;
                border-left: .2rem solid $black02;
            }
        }
        &--next {
            padding-left: 1.7rem;
            @include tab {
                padding-left: 2.4rem;
            }
            &::before {
                right: 1.4rem;
                border-top: .2rem solid $black02;
                border-right: .2rem solid $black02;
            }
        }
        span {
            font-weight: 700;
            font-size: 1.2rem;
            color: $blue;
            padding-right: 0.5rem;
            @include pc {
                font-size: 1.4rem;
                padding-right: 1.4rem;
            }
        }
    }
}

