@use "../common/summarize" as * ;

.service {
    position: relative;
    &__title {
        @include mainTitle;
    }
}

.service-anchor {
    margin-bottom: 3rem;
    @include pc {
        padding-right: 5rem;
        margin-bottom: 5rem;
    }
    &__list {
        @include inner(10);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        grid-column-gap: .7rem;
        grid-row-gap: .7rem;
        @include pc {
            width: 100%;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 1.4rem;
            grid-row-gap: 1.4rem;
        }
    }
    &__item {
        background-color: $white;
        box-shadow: .6rem .6rem 1rem rgba(80, 74, 68, 0.1);
        border-radius: .8rem;
        border: .1rem solid $green;
        padding: 0.1rem;
        display: flex;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        min-height: 56px;
    }
    &__link {
        background-color: $white;
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 1.3;
        font-family: $fontJpMaru;
        border-radius: .7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 1.2rem 0 0.8rem;
        text-align: center;
        &:first-child,:nth-child(2) {
            padding: 0.8rem 0 0.4rem;
            &::after{
                margin-top: 0.4rem;
            }
        }
        @include pc {
            font-size: 1.7rem;
            line-height: 1;
            padding: 2.2rem 0 1.5rem;
            &:first-child,:nth-child(2) {
                padding: 2.2rem 0 1.5rem;
                &::after{
                    margin-top: 1rem;
                }
            }
        }
        &::after {
            content: '';
            top: 50%;
            transform: rotate(45deg);
            width: .8rem;
            height: .8rem;
            margin-top: 0.6rem;
            border-bottom: .2rem solid $black02;
            border-right: .2rem solid $black02;
            @include pc {
                margin-top: 1rem;
            }
        }
    }
}

.service-content {
    padding: 5rem 0;
    background-color: $white;
    @include pc {
        width: calc(100% - 5rem);
        padding: 8rem 0;
        border-radius: 2rem
    }
    &__inner {
        @include inner(15);
        @include pc {
            width: 89.3%;
        }
    }
    &__section {
        & + & {
            margin-top: 8rem;
            @include pc {
                margin-top: 11rem;
            }
        }
    }
    &__title {
        font-weight: 700;
        line-height: 1.3;
        font-size: 2.2rem;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        margin-bottom: 3rem;
        @include pc {
            display: flex;
            align-items: center;
            font-size: 3.2rem;
            margin-bottom: 4.5rem;
        }
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 12rem;
            height: 3rem;
            background-color: $blue;
            font-weight: 500;
            font-size: 1.2rem;
            text-align: center;
            letter-spacing: 0.04em;
            color: $white;
            font-family: $fontJp;
            margin-bottom: 1.2rem;
            border-radius: 10rem;
            @include pc {
                margin-right: 1.5rem;
                margin-bottom: 0;
                font-size: 1.4rem;
            }
        }
    }
    &__flex {
        @include pc {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5rem;
        }
    }
    &__flex-inner {
        @include pc {
            width: calc(440 / 962 * 100%);
        }
    }
    &__image {
        margin-bottom: 4rem;
        @include pc {
            width: calc(470 / 962 * 100%);
            margin-bottom: 0;
        }
    }
    &__subtitle {
        @include gradientText;
        font-weight: 700;
        font-size: 1.4rem;
        margin-bottom: 1.5rem;
        @include pc {
            font-size: 1.8rem;
            margin-bottom: 2rem;
        }
    }
    &__text {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2;
        letter-spacing: 0.02em;
        margin-bottom: 1.5rem;
        @include pc {
            font-size: 1.6rem;
            margin-bottom: 2rem;
        }
    }
    &__list {
        border: .1rem solid $gray02;
        margin-bottom: 4rem;
        @include pc {
            margin-bottom: 5rem;
        }
    }
    &__item {
        display: flex;
        border-bottom: .1rem solid $gray02;
        &:last-of-type {
            border-bottom: none;
        }
    }
    &__item-title {
        padding: 1.5rem;
        width: calc(105 / 345 * 100%);
        background-color: #F8FAFD;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.8;
        letter-spacing: 0.04em;
        @include pc {
            padding: 1.5rem 2.5rem;
            width: 15rem;
            font-size: 1.6rem;
        }
    }
    &__item-text {
        padding: 1.5rem;
        width: calc(240 / 345 * 100%);
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 1.5;
        letter-spacing: 0.04em;
        &--tel {
            color: $blue;
            text-decoration: underline;
        }
        @include pc {
            padding: 1.5rem 2rem;
            width: calc(100% - 15rem);
            font-size: 1.6rem;
        }
    }
    &__img {
        width: 100%;
        iframe {
            width: 100%;
            height: 28rem;
            @include pc {
                height: 42rem;
            }
        }
    }
}