@use "../common/summarize" as * ;

.header {
    position: relative;
    z-index: 10;
}

.sp-header {
    position: fixed;
    inset: 0 auto auto 0;
    width: 100%;
    background-color: $white;
    @include pc {
        display: none;
    }
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 1rem;
    }
    &__logo {
        display: flex;
        align-items: center;
        img {
            display: block;
            width: 4.8rem;
            height: auto;
            margin-right: 0.6rem;
        }
    }
    &__logo-text {
        font-family: $fontJpMaru;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.6;
        letter-spacing: 0.04em;
        margin-bottom: 0.3rem;
    }
    &__logo-name {
        font-family: $fontJpMaru;
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 1.2;
        letter-spacing: -0.06em;
        color: $blue;
    }
    &__btn-wrap {
        display: flex;
    }
    &__contact {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $green;
        color: $white;
        width: 6.4rem;
        height: 7rem;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.6;
        padding-top: 0.5rem;
        img {
            display: block;
            width: 2.4rem;
            height: auto;
            margin-bottom: 0.8rem;
        }
    }
    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $blue;
        color: $white;
        width: 6.3rem;
        height: 7rem;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.6;
        padding-top: .8rem;
        img {
            display: block;
            width: 2.5rem;
            height: auto;
            margin-bottom: 1rem;
        }
    }
    &__content {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        pointer-events: none;
        &--active {
            pointer-events: all;
            .sp-header__content-bg {
                background-color: rgba($color: #212529, $alpha: 0.5);
            }
            .sp-header__content-inner {
                transform: translateX(0);
            }
        }
    }
    &__content-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #ffffff, $alpha: 0);
    }
    &__content-close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        font-weight: 600;
        color: $white;
        font-size: 1rem;
        line-height: 1.6;
        img {
            display: block;
            width: 1.9rem;
            height: auto;
            margin: 0 auto 0.7rem;
        }
    }
    &__content-inner {
        position: absolute;
        top: 0;
        right: 0;
        overflow-y: scroll;
        width: 31.5rem;
        height: 100vh;
        background-color: $blue;
        transform: translateX(100%);
        transition: .3s transform;
        padding: 7.8rem 0 4rem;
    }
    &__content-list {
        margin-bottom: 3.8rem;
    }
    &__content-item {
        text-align: center;
        margin-bottom: 2.5rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &__content-link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        font-family: $fontJpMaru;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1;
        letter-spacing: 0.08em;
        color: $white;
        &::before {
            content: attr(data-name);
            @include gradientText;
            font-family: $fontEn;
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 1;
            letter-spacing: 0.08em;
            margin-top: 0.8rem;
        }
    }
    &__content-contact {
        position: relative;
        width: 26.9rem;
        height: 6rem;
        background-color: $green;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 3rem;
        border-radius: 10rem;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 1;
        letter-spacing: 0.02em;
        font-family: $fontJpMaru;
    }
    &__content-phone-list {
        margin-bottom: 3rem;
    }
    &__content-phone-item {
        text-align: center;
        margin-bottom: 2rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &__content-phone-text {
        font-family: $fontJpMaru;
        font-weight: 500;
        font-size: 1.4rem;
        letter-spacing: 0.04em;
        margin-bottom: 1.6rem;
        color: $white;
    }
    &__content-phone-link {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $fontEn;
        font-weight: 600;
        font-size: 2.4rem;
        margin-bottom: 0.04rem;
        color: $white;
        img {
            display: block;
            width: 2.4rem;
            height: auto;
            margin-right: .7rem;
        }
    }
    &__content-phone-subtext {
        font-weight: 300;
        font-size: 1.2rem;
        line-height: 1.7;
        letter-spacing: 0.1em;
        margin-top: 0.4rem;
        color: $white;
    }
    &__content-policy {
        text-align: center;
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 1.5;
        color: $white;
        margin-bottom: 3rem;
    }
    &__content-sns-list {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__content-sns-item {
        margin-right: 2.7rem;
        width: 3.2rem;
        &:last-of-type {
            margin-right: 0;
        }
    }
}
.pc-header {
    display: none;
    position: fixed;
    inset: 2rem auto auto 2rem;
    max-height: 72rem;
    height: calc(100vh - 4rem);
    width: 25rem;
    background-color: $white;
    box-shadow: 1rem 1rem 2rem rgba(80, 74, 68, 0.05);
    border-radius: 2rem;
    overflow-y: scroll;
    padding: 3rem 0;
    @include scrollBarRemove;
    transition: .3s all;
    @include pc {
        display: block;
    }
    &__logo {
        width: 100%;
        padding: 0 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2.8rem;
        img {
            display: block;
            width: 7.7rem;
            height: auto;
            margin-right: 1.1rem;
        }
    }
    &__logo-text {
        font-family: $fontJpMaru;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.5;
        letter-spacing: 0.06em;
        margin-bottom: 0.6rem;
    }
    &__logo-name {
        font-family: $fontJpMaru;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.3;
        color: $blue;
    }
    &__list {
        margin-bottom: 2.8rem;
    }
    &__content-item {
        border-bottom: .1rem solid $white02;
        &:first-of-type {
            border-top: .1rem solid $white02;
        }
    }
    &__content-link {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column-reverse;
        padding: 1.7rem 2.5rem;
        font-family: $fontJpMaru;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 100%;
        letter-spacing: 0.08em;
        &::before {
            content: attr(data-name);
            @include gradientText;
            font-family: $fontEn;
            font-weight: 600;
            font-size: 1.2rem;
            letter-spacing: 0.08em;
            margin-top: 0.8rem;
            line-height: 1;
        }
        &::after {
            content: '';
            display: block;
            width: 1rem;
            height: 1rem;
            border: .2rem solid $black02;
            border-bottom: none;
            border-left: none;
            position: absolute;
            inset: 50% 2rem auto auto;
            transform: translateY(-50%) rotate(45deg);
        }
    }
    &__content-phone-list {
        padding: 0 2.5rem;
    }
    &__content-phone-item {
        margin-bottom: 1.8rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &__content-phone-text {
        font-weight: 500;
        font-size: 1.4rem;
        letter-spacing: 0.04em;
        margin-bottom: 1.5rem;
    }
    &__content-phone-link {
        font-weight: 600;
        font-size: 2.4rem;
        letter-spacing: 0.02em;
        font-family: $fontEn;
        color: $blue;
        display: flex;
        align-items: center;
        img {
            display: block;
            width: 2.4rem;
            height: auto;
            margin-right: .6rem;
        }
    }
    &__content-phone-subtext {
        text-align: center;
        font-weight: 400;
        font-size: 1.2rem;
        letter-spacing: 0.1em;
        margin-top: 0.4rem;
    }
    &__contact {
        display: none;
        position: fixed;
        inset: 0 0 auto auto;
        width: 15.4rem;
        height: 13rem;
        border-radius: 0 0 0 2rem;
        background-color: $green;
        filter: drop-shadow(.6rem .6rem 1.6rem rgba(16, 180, 121, 0.6));
        color: $white;
        font-weight: 700;
        font-size: 1.8rem;
        font-family: $fontJpMaru;
        @include pc {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        &::before {
            content: '';
            display: block;
            background-image: url(../images/common/icon_mail.svg);
            background-position: center center;
            background-repeat: no-repeat;
            width: 2.6rem;
            height: 2rem;
            margin-bottom: 1.5rem;
        }
        &::after {
            content: 'CONTACT';
            display: block;
            font-weight: 600;
            font-size: 1.2rem;
            font-family: $fontEn;
            margin-top: 1.1rem;
        }
    }
    &--scroll {
        max-height: 56rem;
        width: 20rem;
        padding: 2.1rem 0;
        .pc-header__logo {
            padding: 0 2rem;
            margin-bottom: 2rem;
            img {
                width: 6.4rem;
                margin-right: 1.2rem;
            }
        }
        .pc-header__logo-text {
            font-size: 1rem;
            margin-bottom: 0.2rem;
        }
        .pc-header__logo-name {
            font-size: 1.4rem;
        }
        .pc-header__content-link {
            padding: 1.2rem 2.5rem;
            font-size: 1.6rem;
            &::before {
                font-size: 1rem;
                margin-top: 0.6rem;
            }
        }
        .pc-header__list {
            margin-bottom: 1.2rem;
        }
        .pc-header__content-phone-text {
            font-size: 1.2rem;
            margin-bottom: 0.8rem;
        }
        .pc-header__content-phone-link {
            font-size: 1.8rem;
        }
        .pc-header__content-phone-subtext {
            font-size: 1.2rem;
        }
        .pc-header__content-phone-item {
            margin-bottom: 1.3rem;
        }
    }
}