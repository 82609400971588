@use "../common/summarize" as * ;

.top {
    position: relative;
}

.mainvisual {
    position: relative;
    margin-bottom: 2rem;
    z-index: 2;
    @include pc {
        padding-top: 3rem;
        padding-right: 3rem;
        margin-bottom: 4rem;
    }
    &__wrap {
        position: relative;
        z-index: 1;
    }
    &__text {
        position: absolute;
        inset: auto auto 4.2rem 0;
        width: 24.8rem;
        z-index: 10;
        @include pc {
            width: calc(430 / 1112 * 100%);
            inset: auto auto 7rem 0;
        }
    }
    &__list {
        overflow: hidden;
        @include tab {
            border-radius: 2rem;
        }
    }
    .swiper-pagination {
        bottom: 1.7rem;
        @include pc {
            bottom: 3rem;
            left: 3rem;
            width: 50%;
            text-align: left;
        }
    }
    .swiper-pagination-bullet {
        width: 0.8rem;
        margin: 0 0.4rem;
        background-color: $white;
        opacity: 1;
        @include tab {
            width: 1rem;
            height: 1rem;
        }
    }
    .swiper-pagination-bullet-active {
        background-color: $green;
    }
}

.top-update {
    @include inner();
    background-color: $white;
    border-radius: 1rem;
    padding: 2rem;
    margin-bottom: 3rem;
    @include tab {
        margin-bottom: 16.6rem;
    }
    @include pc {
        width: calc(100% - 3rem);
        padding: 3rem;
        border-radius: 10rem;
        display: flex;
        align-items: center;
        margin: 0 0 16.6rem 0;
    }
    &__text {
        position: relative;
        font-weight: 700;
        font-size: 1.4rem;
        letter-spacing: 0.08em;
        font-family: $fontJpMaru;
        padding-left: 2.7rem;
        margin-bottom: 1.4rem;
        @include pc {
            font-size: 1.8rem;
            padding-left: 4rem;
            margin-bottom: 0;
            margin-right: 4.1rem;
            padding-right: 4.1rem;
            border-right: .1rem solid $gray02;
        }
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 2rem;
            height: 2rem;
            background-image: url(../images/common/icon_update.svg);
            background-position: center center;
            background-repeat: no-repeat;
            @include pc {
                width: 2.8rem;
                height: 2.8rem;
            }
        }
    }
    &__time {
        font-weight: 500;
        font-size: 1.2rem;
        letter-spacing: 0.08em;
        font-family: $fontEn;
        color: $gray;
        margin-right: 1.3rem;
        @include pc {
            font-size: 1.4rem;
            margin-right: 2rem;
        }
    }
    &__link {
        display: inline-block;
        font-weight: 400;
        font-size: 1.4rem;
        letter-spacing: .1rem;
        color: $blue;
        text-decoration: underline;
        @include pc {
            font-size: 1.6rem;
        }
    }
}

.top-about {
    position: relative;
    padding: 2.8rem 0 26.8rem;
    background-color: $white;
    border-radius: 1rem;
    @include inner();
    margin-bottom: 12.2rem;
    @include tab {
        padding: 8rem 0;
        width: 100%;
        border-radius: 2rem 0 0 2rem;
        margin-bottom: 10rem;
    }
    &__inner {
        padding: 0 3rem;
        @include tab {
            padding: 0 7rem;
            width: calc(470 / 1142 * 100% + 14rem);
        }
    }
    &__title {
        font-weight: 600;
        font-size: 1.4rem;
        font-family: $fontEn;
        margin-bottom: 1.1rem;
        @include gradientText;
    }
    &__subtitle {
        font-weight: 700;
        font-size: 2rem;
        line-height: 1.7;
        font-family: $fontJpMaru;
        letter-spacing: 0.02em;
        margin-bottom: 2rem;
        @include tab {
            font-size: 2.6rem;
            line-height: 1.6;
            margin-bottom: 4rem;
        }
    }
    &__text {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.8;
        margin-bottom: 1.5rem;
        @include tab {
            font-size: 1.6rem;
            line-height: 2;
            letter-spacing: .1rem;
            margin-bottom: 3rem;
        }
        &--bold {
            font-weight: 700;
        }
    }
    &__btn {
        a {
            @include btn(29.5rem, 6rem, 1.6rem);
        }
    }
    &__images {
        position: absolute;
        border-radius: 2rem;
        overflow: hidden;
        inset: auto auto -6.2rem 50%;
        transform: translateX(-50%);
        width: 29.5rem;
        @include tab {
            width: calc(452 / 1142 * 100%);
            max-width: 45.2rem;
            inset: -7.6rem 8rem auto auto;
            transform: translateX(0);
        }
    }
}

.top-service {
    padding: 6rem 0;
    background-color: rgba(255, 255, 255, 0.7);
    @include tab {
        padding: 8rem 0;
    }
    .sc-inner {
        @include tab {
            padding: 0 2rem;
        }
        @include pc {
            padding: 0 8rem 0 30rem;
        }
    }
    &__title {
        font-weight: 700;
        font-size: 2rem;
        font-family: $fontJpMaru;
        text-align: center;
        letter-spacing: 0.04em;
        margin-bottom: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include tab {
            font-size: 2.8rem;
            margin-bottom: 4.8rem;
            align-items: start;
        }
        &--small {
            @include gradientText;
            font-weight: 600;
            font-size: 1.4rem;
            font-family: $fontEn;
            margin-bottom: 1.5rem;
            @include tab {
                margin-bottom: 2rem;
            }
        }
    }
    &__list {
        @include inner(15);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        grid-column-gap: .9rem;
        grid-row-gap: 1rem;
        margin-bottom: 4rem;
        @include tab {
            width: 100%;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 1.5rem;
            grid-row-gap: 1.5rem;
        }
        @include pc {
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 2rem;
            grid-row-gap: 2rem;
        }
    }
    &__link {
        display: block;
        filter: drop-shadow(1rem 1rem 2rem rgba(80, 74, 68, 0.1));
        transform: translateZ(0);
    }
    &__btn {
        a {
            @include btn(29.5rem, 6rem, 1.6rem);
            margin: 0 auto;
        }
    }
}

.top-news {
    overflow: hidden;
    padding: 7rem 0;
    @include tab {
        padding: 8rem 0 18rem 0;
    }
    @include pc {
        padding: 8rem 8rem 18rem 0;
    }
    &__inner {
        @include inner(20);
        display: flex;
        justify-content: space-between;
        margin-bottom: 3rem;
        align-items: end;
        @include pc {
            width: 100%;
            margin-bottom: 3.8rem;
        }
    }
    &__title {
        font-weight: 700;
        font-size: 2rem;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        @include tab {
            font-size: 2.8rem;
        }
        span {
            @include gradientText;
            font-weight: 600;
            font-size: 1.4rem;
            font-family: $fontEn;
            letter-spacing: 0.08em;
            margin-bottom: 1rem;
            @include tab {
                margin-bottom: 1.4rem;
            }
        }
    }
    &__link {
        a {
            position: relative;
            display: block;
            padding-right: 2.4rem;
            font-weight: 400;
            font-size: 1.4rem;
            letter-spacing: .1rem;
            line-height: 1.4;
            color: $blue;
            @include tab {
                font-size: 1.6rem;
            }
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: 1.8rem;
                height: 1.8rem;
                background-image: url(../images/common/icon_arrow_b.svg);
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
    &__list {
        @include inner(40);
        @include tab {
            @include inner(20);
        }
        @include pc {
            width: 100%;
        }
    }
    &__list-inner {
        @include tab {
            justify-content: space-between;
        }
    }
    &__item {
        padding: 0 1.5rem;
        @include tab {
            padding: 0;
            width: calc((100% - 4rem) / 3);
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
    &__item-link {
        display: block;
        background-color: $white;
        box-shadow: 1rem 1rem 2rem rgba(80, 74, 68, 0.05);
        border-radius: 1rem;
        overflow: hidden;
        @include tab {
            border-radius: 2rem;
        }
    }
    &__item-content {
        padding: 2rem;
        @include tab {
            padding: 2rem 2rem 3rem;
        }
    }
    &__item-title {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.5;
        letter-spacing: 0.06em;
        margin-bottom: 0.5rem;
        color: $blue;
        @include tab {
            font-size: 1.8rem;
            margin-bottom: 1.5rem;
        }
    }
    &__item-text {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.5;
        letter-spacing: 0.04em;
        margin-bottom: 1.1rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @include tab {
            -webkit-line-clamp: 2;
            margin-bottom: 2rem;
        }
    }
    &__item-flex {
        display: flex;
        align-items: center;
    }
    &__item-label {
        display: inline-block;
        padding: 0.4rem 1rem;
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 1.5;
        color: $green;
        letter-spacing: 0.04em;
        border: .1rem solid $green;
        border-radius: 6rem;
        margin-right: 1rem;
        @include tab {
            font-size: 1.2rem;
            margin-right: 1.4rem;
        }
    }
    &__item-time {
        display: inline-block;
        font-weight: 500;
        font-size: 1.2rem;
        font-family: $fontEn;
        color: $gray;
        letter-spacing: 0.04em;
    }
}

.top-recruit {
    position: relative;
    padding: 2.8rem 0 26.8rem;
    background-color: $white;
    border-radius: 1rem;
    @include inner();
    margin-bottom: 12.2rem;
    @include tab {
        padding: 6rem 0;
        width: 100%;
        border-radius: 2rem 0 0 2rem;
        margin-bottom: 10rem;
    }
    &__inner {
        padding: 0 3rem;
        @include tab {
            padding: 0 7rem;
            width: calc(470 / 1142 * 100% + 14rem);
            margin-left: auto;
        }
    }
    &__title {
        font-weight: 600;
        font-size: 1.4rem;
        font-family: $fontEn;
        margin-bottom: 1.1rem;
        @include gradientText;
    }
    &__subtitle {
        font-weight: 700;
        font-size: 2rem;
        line-height: 1.7;
        font-family: $fontJpMaru;
        letter-spacing: 0.02em;
        margin-bottom: 2rem;
        @include tab {
            font-size: 2.6rem;
            line-height: 1.6;
        }
    }
    &__text {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.8;
        margin-bottom: 1.5rem;
        @include tab {
            font-size: 1.6rem;
            line-height: 2;
            letter-spacing: .1rem;
            margin-bottom: 3rem;
        }
        &--bold {
            font-weight: 700;
        }
    }
    &__btn {
        @include btn(29.5rem, 6rem, 1.6rem);
    }
    &__images {
        position: absolute;
        border-radius: 2rem;
        overflow: hidden;
        inset: auto auto -6.2rem 50%;
        transform: translateX(-50%);
        width: 29.5rem;
        @include tab {
            width: calc(452 / 1142 * 100%);
            max-width: 45.2rem;
            inset: -7.6rem auto auto 8rem;
            transform: translateX(0);
        }
    }
}

.top-access {
    @include tab {
        padding: 0 2rem;
    }
    @include pc {
        padding-right: 12rem;
    }
    &__inner {
        @include inner(20);
        display: flex;
        justify-content: space-between;
        margin-bottom: 3rem;
        align-items: end;
        @include tab {
            width: 100%;
            margin-bottom: 3.8rem;
        }
    }
    &__title {
        font-weight: 700;
        font-size: 2rem;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        @include tab {
            font-size: 2.8rem;
        }
        span {
            @include gradientText;
            font-weight: 600;
            font-size: 1.4rem;
            font-family: $fontEn;
            letter-spacing: 0.08em;
            margin-bottom: 1rem;
            @include tab {
                margin-bottom: 1.4rem;
            }
        }
    }
    &__link {
        a {
            position: relative;
            display: block;
            padding-right: 2.4rem;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 1.4;
            letter-spacing: .1rem;
            color: $blue;
            @include tab {
                font-size: 1.6rem;
            }
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: 1.8rem;
                height: 1.8rem;
                background-image: url(../images/common/icon_arrow_b.svg);
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
    &__map {
        @include inner(10);
        @include tab {
            width: 100%;
        }
        iframe {
            width: 100%;
            height: 46rem;
        }
    }
}

.top-contact {
    padding: 6rem 0 8rem;
    @include pc {
        padding: 9.5rem 8rem 8rem 0;
    }
    &__inner {
        @include inner(20);
        @include tab {
            display: flex;
            justify-content: space-between;
            align-items: start;
        }
        @include pc {
            width: 100%;
        }
    }
    &__title {
        font-weight: 700;
        font-size: 2rem;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        margin-bottom: 2.5rem;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        @include pc {
            font-size: 2.8rem;
            margin-bottom: 0;
        }
        span {
            @include gradientText;
            font-weight: 600;
            font-size: 1.4rem;
            font-family: $fontEn;
            letter-spacing: 0.08em;
            margin-bottom: 1.2rem;
        }
    }
    &__list {
        @include tab {
            display: contents;
        }
    }
    &__item {
        display: flex;
        margin-bottom: 2rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &__item-icon {
        width: 6.3rem;
        margin-right: 1.6rem;
        @include pc {
            width: 9rem;
            margin-right: 2.7rem;
        }
    }
    &__item-text {
        font-weight: 500;
        font-size: 1.4rem;
        letter-spacing: 0.04em;
        margin-bottom: 0.6rem;
        @include pc {
            font-size: 1.6rem;
            margin-bottom: 1.2rem;
        }
    }
    &__item-tel {
        font-weight: 600;
        font-size: 2.4rem;
        letter-spacing: 0.04em;
        color: $blue;
        font-family: $fontEn;
        @include pc {
            font-size: 2.8rem;
        }
    }
    &__item-annotation {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.7;
        margin-bottom: 0.7rem;
        @include pc {
            font-size: 1.4rem;
            margin-bottom: 1.1rem;
        }
    }
    &__item-btn{
        @include btn(25.6rem, 5.5rem, 1.6rem);
        margin-top: 1.3rem;
        @include pc {
            margin-top: 2.1rem;
            font-size: 1.8rem;
            width: 26.9rem;
            height: 6rem;
        }
    }
}

.top-banner {
    @include inner(20);
    &__list {
        @include tab {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto;
            grid-column-gap: 2rem;
            grid-row-gap: 2rem;
        }
    }
    &__item {
        margin-bottom: 1.5rem;
        @include tab {
            margin-bottom: 0;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &__link {
        display: block;
    }
}

.top-floating {
    @include inner(10);
    width: 100%;
    position: fixed;
    inset: auto auto 0 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: $white;
    z-index: 9;
    box-shadow: .8rem .8rem .8rem rgba(80, 74, 68, 0.1);
    opacity: 1;
    transition: .3s opacity;
    @include tab {
        width: 33.8rem;
        inset: auto 3rem 3rem auto;
        transform: translateX(0);
        padding: 1.5rem;
        border-radius: 1rem;
    }
    &--none {
        opacity: 0;
        pointer-events: none;
    }
    &__img {
        width: calc(64 / 335 * 100%);
        @include tab {
            width: calc(128 / 308 * 100%);
        }
    }
    &__content {
        width: calc(257 / 335 * 100%);
        @include tab {
            width: calc(160 / 308 * 100%);
        }
    }
    &__title {
        @include gradientText;
        font-weight: 600;
        font-size: 1.4rem;
        font-family: $fontEn;
        letter-spacing: 0.04em;
        margin-bottom: 0.3rem;
        padding-top: 0.5rem;
        @include tab {
            font-size: 2.2rem;
            margin-bottom: 1rem;
            padding-top: 1rem;
        }
    }
    &__text {
        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.4;
        margin-bottom: 0.4rem;
        @include tab {
            margin-bottom: 1.3rem;
        }
    }
    &__link {
        position: relative;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.4;
        letter-spacing: .1rem;
        text-align: right;
        color: $blue;
        padding-right: 2.2rem;
        &::before {
            content: '';
            position: absolute;
            inset: 50% 0 auto auto;
            transform: translateY(-50%);
            width: 1.8rem;
            height: 1.8rem;
            background-image: url(../images/common/icon_arrow_b.svg);
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}