// 共通読み込み
@use "common/reset";
@use "common/common";

// モジュール
@use "modules/header";
@use "modules/footer";
@use "modules/recruitHeader";
@use "modules/recruitFooter";
@use "modules/otherHeader";
@use "modules/breadcrumb";

// ページごとのscss読み込み
@use "pages/top";
@use "pages/news";
@use "pages/service";
@use "pages/about";
@use "pages/access";
@use "pages/recruit";
@use "pages/interview";
@use "pages/privacypolicy";
@use "pages/contact";



@import 'swiper/swiper-bundle.css';