@use "../common/summarize" as * ;

.footer {
    position: relative;
    background: $gradationFooter;
    padding: 5rem 0 3rem;
    color: $white;
    @include tab {
        padding: 7rem 0 2rem;
    }
    &__inner {
        @include tab {
            padding: 0 3rem;
        }
        @include pc {
            padding-left: 30rem;
        }
    }
    &__content {
        padding: 0 3.2rem;
        margin-bottom: 7.6rem;
        @include tab {
            display: flex;
            justify-content: space-between;
            align-items: start;
            padding: 0;
            margin-bottom: 8.3rem;
        }
    }
    &__name {
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 1.6;
        margin-bottom: 1rem;
        font-family: $fontJpMaru;
    }
    &__address {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.8;
        letter-spacing: 0.04em;
        font-style: normal;
        margin-bottom: 3.4rem;
    }
    &__address-annotation {
        font-weight: 400;
        font-size: 1.2rem;
    }
    &__sns {
        display: flex;
        align-items: center;
        justify-content: center;
        @include pc {
            padding-right: 5rem;
        }
    }
    &__sns-text {
        position: relative;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.6;
        letter-spacing: 0.04em;
        font-family: $fontJpMaru;
        margin-right: 11.3rem;
        &::before {
            content: '';
            position: absolute;
            inset: 50% -2rem auto auto;
            transform: translate(100%, -50%);
            width: 6.5rem;
            height: 0.1rem;
            background-color: $white;
        }
    }
    &__sns-list {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__sns-item {
        margin-right: 2.9rem;
        &:last-of-type {
            margin-right: 0;
        }
    }
    &__sns-link {
        display: block;
        width: 3.1rem;
    }
    &__under {
        padding: 0 2.5rem;
        @include tab {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;
            padding: 0;
        }
    }
    &__privacy {
        text-align: center;
        margin-bottom: 1.5rem;
        @include tab {
            text-align: left;
            margin-bottom: 0;
        }
        a {
            display: inline-block;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: calc(20 / 14 * 100%);
        }
    }
    &__copy {
        text-align: center;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.4;
        letter-spacing: 0.04em;
        @include tab {
            font-size: 1.3rem;
            text-align: left;
        }
    }
}
