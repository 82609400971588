@use "../common/summarize" as * ;

.about {
    position: relative;
    &__title {
        @include mainTitle;
    }
    &__text {
        font-size: 1.6rem;
        font-family: Noto Sans JP;
        line-height: 1.75;
        margin-bottom: 3rem;
        @include pc {
            margin-bottom: 4rem;
        }
    }
    &__box {
        background-color: $white;
        padding: 5rem 0;
        @include tab {
            padding: 8rem 0;
            border-radius: 2rem;
        }
        @include pc {
            width: calc(100% - 5rem);
        }
    }
    &__inner {
        padding: 0 1rem;
        @include tab {
            padding: 0 2rem;
        }
        @include pc {
            padding: 0 7rem;
        }
    }
    &__content {
        &+& {
            margin-top: 8rem;
            @include pc {
                margin-top: 11rem;
            }
        }
    }
    &__content-inner {
        @include tab {
            display: flex;
            justify-content: space-between;
        }
        &--center {
            @include tab {
                align-items: center;
            }
        }
    }
    &__content-box {
        padding: 0 1rem;
        @include tab {
            padding: 0;
            width: calc(498 / 962 * 100%);
        }
        & + .about__content-img {
            margin-top: 3rem;
            @include tab {
                margin-top: 0;
            }
        }
    }
    &__subtitle {
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 1.6;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
        margin-bottom: 1.8rem;
        text-align: center;
        @include tab {
            font-size: 2.8rem;
            margin-bottom: 2.8rem;
        }
        &--left {
            text-align: left;
        }
        span {
            @include gradientText;
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 1;
            font-family: $fontEn;
            letter-spacing: 0.08em;
            padding-bottom: 0.6rem;
        }
    }
    &__text {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2;
        letter-spacing: 0.02em;
        &+& {
            margin-top: 2rem;
        }
        &--bold {
            font-weight: 700;
        }
    }
    &__text-name {
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 1;
        font-family: $fontJpMaru;
        letter-spacing: 0.02em;
        text-align: right;
        margin-top: 2.7rem;
        @include tab {
            font-size: 2.8rem;
        }
        span {
            display: block;
            font-size: 1.4rem;
            margin-bottom: 1.2rem;
        }
    }
    &__content-img {
        width: 25rem;
        margin: 0 auto;
        @include tab {
            width: calc(398 / 962 * 100%);
        }
        & + .about__content-box {
            margin-top: 3rem;
            @include tab {
                margin-top: 0;
            }
        }
    }
    &__philosophy-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        grid-column-gap: 0;
        grid-row-gap: 1rem;
        counter-reset: number 0;
        @include tab {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 2.2rem;
            grid-row-gap: 2.2rem;
        }
    }
    &__philosophy-item {
        position: relative;
        background: linear-gradient(271.19deg, #E8F7FF 0.15%, #F2FAFF 99.61%);
        border-radius: .6rem;
        padding: 1.4rem 1.7rem 1.4rem 4.7rem;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.5;
        letter-spacing: 0.04em;
        @include tab {
            font-size: 1.6rem;
            padding: 1.7rem 2.5rem 1.7rem 5.5rem;
            display: flex;
            align-items: center;
        }
        &::before {
            counter-increment: number 1;
            content: counter(number);
            @include gradientText;
            font-weight: 600;
            font-size: 2.2rem;
            font-family: $fontEn;
            letter-spacing: 0.08em;
            position: absolute;
            inset: 50% auto auto 1.4rem;
            transform: translateY(-50%);
            @include tab {
                font-size: 2.4rem;
                inset: 50% auto auto 2.4rem;
            }
        }
    }
    &__list {
        border: .1rem solid $gray02;
        margin-bottom: 4rem;
        @include pc {
            margin-bottom: 5rem;
        }
    }
    &__item {
        display: flex;
        border-bottom: .1rem solid $gray02;
        &:last-of-type {
            border-bottom: none;
        }
    }
    &__item-title {
        padding: 1.5rem;
        width: calc(105 / 345 * 100%);
        background-color: #F8FAFD;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.8;
        letter-spacing: 0.04em;
        @include pc {
            padding: 1.5rem 2.5rem;
            width: 15rem;
            font-size: 1.6rem;
        }
    }
    &__item-text {
        padding: 1.5rem;
        width: calc(240 / 345 * 100%);
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.5;
        letter-spacing: 0.04em;
        @include pc {
            padding: 1.5rem 2rem;
            width: calc(100% - 15rem);
            font-size: 1.6rem;
        }
    }
    &__history-item {
        display: flex;
        &+& {
            margin-top: 1.5rem;
            @include tab {
                margin-top: 2rem;
            }
        }
        &:last-of-type .about__history-item-num::before {
            display: none;
        }
    }
    &__history-item-num {
        position: relative;
        @include gradientText;
        font-weight: 500;
        font-size: 1.3rem;
        padding-top: 0.6rem;
        font-family: $fontJpMaru;
        letter-spacing: 0.02em;
        padding-left: 2.8rem;
        white-space: nowrap;
        word-break: keep-all;
        margin-right: 1.5rem;
        @include tab {
            font-size: 1.6rem;
            padding-left: 3.6rem;
            margin-right: 6.2rem;
        }
        &::before {
            content: '';
            position: absolute;
            inset: 2rem auto auto .9rem;
            width: .2rem;
            height: 100%;
            background: $gray02;
            @include tab {
                inset: 2.3rem auto auto 1rem;
            }
        }
        span {
            position: absolute;
            inset: 0 auto auto 0;
            width: 2rem;
            height: 2rem;
            margin-top: 0.3rem;
            background: $gradation01;
            border-radius: 10rem;
            @include tab {
                width: 2.2rem;
                height: 2.2rem;
            }
            &::before {
                content: '';
                position: absolute;
                inset: 50% auto auto 50%;
                transform: translate(-50%, -50%);
                width: .7rem;
                height: .7rem;
                background: $white;
                border-radius: 10rem;
                @include tab {
                    width: 1rem;
                    height: 1rem;
                }
            }
        }
    }
    &__history-item-text {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.75;
        letter-spacing: 0.04em;
        @include tab {
            margin-top: -0.5rem;
            font-size: 1.6rem;
        }
    }
}
