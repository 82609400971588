@use "../common/summarize" as * ;

.recruit-header {
    position: relative;
    z-index: 10;
}

.sp-recruit-header {
    position: fixed;
    inset: 0 auto auto 0;
    width: 100%;
    background-color: $white;
    @include pc {
        display: none;
    }
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 1rem;
    }
    &__logo {
        display: flex;
        align-items: center;
        img {
            display: block;
            width: 4.8rem;
            height: auto;
            margin-right: 0.6rem;
        }
    }
    &__logo-text {
        font-family: $fontJpMaru;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.6;
        letter-spacing: 0.04em;
    }
    &__logo-name {
        font-family: $fontJpMaru;
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 1.2;
        letter-spacing: -0.06em;
        color: $blue;
    }
    &__logo-site {
        display: inline-block;
        background-color: $blue;
        color: $white;
        font-size: 1rem;
        font-family: $fontJpMaru;
        font-weight: 700;
        padding: .2rem 1rem;
        border-radius: 10rem;
        margin-top: 0.4rem;
    }
    &__btn-wrap {
        display: flex;
    }
    &__contact {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $green;
        color: $white;
        width: 6.4rem;
        height: 7rem;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.6;
        padding-top: 0.5rem;
        img {
            display: block;
            width: 2.4rem;
            height: auto;
            margin-bottom: 0.8rem;
        }
    }
    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $blue;
        color: $white;
        width: 6.3rem;
        height: 7rem;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.6;
        padding-top: .8rem;
        img {
            display: block;
            width: 2.5rem;
            height: auto;
            margin-bottom: 1rem;
        }
    }
    &__content {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        pointer-events: none;
        &--active {
            pointer-events: all;
            .sp-recruit-header__content-bg {
                background-color: rgba($color: #212529, $alpha: 0.5);
            }
            .sp-recruit-header__content-inner {
                transform: translateX(0);
            }
        }
    }
    &__content-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #ffffff, $alpha: 0);
    }
    &__content-close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        font-weight: 600;
        color: $white;
        font-size: 1rem;
        line-height: 1.6;
        img {
            display: block;
            width: 1.9rem;
            height: auto;
            margin: 0 auto 0.7rem;
        }
    }
    &__content-inner {
        position: absolute;
        top: 0;
        right: 0;
        overflow-y: scroll;
        width: 31.5rem;
        height: 100vh;
        background-color: $blue;
        transform: translateX(100%);
        transition: .3s transform;
        padding: 7.8rem 0 4rem;
    }
    &__content-list {
        margin-bottom: 3.8rem;
    }
    &__content-item {
        text-align: center;
        margin-bottom: 2.5rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &__content-link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        font-family: $fontJpMaru;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1;
        letter-spacing: 0.08em;
        line-height: 1.5;
        color: $white;
        &::before {
            content: attr(data-name);
            @include gradientText;
            font-family: $fontEn;
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 1;
            letter-spacing: 0.08em;
            margin-top: 0.8rem;
        }
        &--icon {
            display: inline-block;
            width: 1.3rem;
            vertical-align: baseline;
        }
    }
    &__content-contact {
        position: relative;
        width: 26.9rem;
        height: 6rem;
        background-color: $green;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 3rem;
        border-radius: 10rem;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 1;
        letter-spacing: 0.02em;
        font-family: $fontJpMaru;
    }
    &__content-phone-list {
        margin-bottom: 3rem;
    }
    &__content-phone-item {
        text-align: center;
        margin-bottom: 2rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &__content-phone-text {
        font-family: $fontJpMaru;
        font-weight: 500;
        font-size: 1.4rem;
        letter-spacing: 0.04em;
        margin-bottom: 1.6rem;
        color: $white;
    }
    &__content-phone-link {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $fontEn;
        font-weight: 600;
        font-size: 2.4rem;
        margin-bottom: 0.04rem;
        color: $white;
        img {
            display: block;
            width: 2.4rem;
            height: auto;
            margin-right: .7rem;
        }
    }
    &__content-phone-subtext {
        font-weight: 300;
        font-size: 1.2rem;
        line-height: 1.7;
        letter-spacing: 0.1em;
        margin-top: 0.4rem;
        color: $white;
    }
    &__content-policy {
        text-align: center;
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 1.5;
        color: $white;
        margin-bottom: 3rem;
    }
    &__content-sns-list {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__content-sns-item {
        margin-right: 2.7rem;
        width: 3.2rem;
        &:last-of-type {
            margin-right: 0;
        }
    }
}
.pc-recruit-header {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: space-between;
    padding-right: 18.4rem;
    padding-left: 1.7rem;
    padding-top: 2rem;
    transition: .3s all;
    @include pc {
        display: flex;
    }
    &__logo {
        width: 29.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s all;
        @include pc {
            width: 33rem;
        }
        img {
            display: block;
            width: 7.7rem;
            height: auto;
            margin-right: 1.1rem;
            transition: .3s all;
        }
    }
    &__logo-text {
        font-family: $fontJpMaru;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.5;
        letter-spacing: 0.06em;
        margin-bottom: 0.6rem;
    }
    &__logo-name {
        font-family: $fontJpMaru;
        font-weight: 700;
        font-size: 1.7rem;
        line-height: 1.3;
        color: $blue;
    }
    &__logo-site {
        font-family: $fontJpMaru;
        font-weight: 700;
        font-size: 1.5rem;
        background-color: $blue;
        color: $white;
        display: inline-block;
        border-radius: 10rem;
        padding: 0.4rem 1rem;
        margin-top: 0.6rem;
    }
    &__link {
        text-align: right;
        a {
            position: relative;
            display: inline-block;
            font-weight: 700;
            font-size: 1.2rem;
            line-height: 1.8;
            font-family: $fontJpMaru;
            letter-spacing: 0.04em;
            margin-bottom: 1.2rem;
            &::after {
                content: '';
                display: inline-block;
                width: 1.3rem;
                height: 1.2rem;
                background-image: url(../images/common/icon_popup.svg);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                margin-left: 0.5rem;
            }
        }
    }
    &__list {
        display: flex;
    }
    &__content-item {
        margin-right: 3.5rem;
        &:last-of-type {
            margin-right: 0;
        }
    }
    &__content-link {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.8;
        font-family: $fontJpMaru;
        letter-spacing: 0.04em;
    }
    &__contact {
        display: none;
        position: absolute;
        inset: 0 0 auto auto;
        width: 15.4rem;
        height: 13rem;
        border-radius: 0 0 0 2rem;
        background-color: $green;
        filter: drop-shadow(.6rem .6rem 1.6rem rgba(16, 180, 121, 0.6));
        color: $white;
        font-weight: 700;
        font-size: 1.8rem;
        font-family: $fontJpMaru;
        transition: .3s all;
        @include pc {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        &::before {
            content: '';
            display: block;
            background-image: url(../images/common/icon_mail.svg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 2.6rem;
            height: 2rem;
            margin-bottom: 1.5rem;
        }
        &::after {
            content: 'ENTRY';
            display: block;
            font-weight: 600;
            font-size: 1.2rem;
            font-family: $fontEn;
            margin-top: 1.1rem;
        }
    }
    &--scroll {
        background-color: $white;
        padding: 1rem 17.1rem 1rem 1.5rem;
        min-height: 7rem;
        .pc-recruit-header__contact {
            width: 15.4rem;
            height: 7rem;
            border-radius: 0;
            font-size: 1.4rem;
            padding-left: 3rem;
            &:before {
                position: absolute;
                width: 2.1rem;
                height: 1.6rem;
                margin-bottom: 0;
                top: 50%;
                left: 3rem;
                transform: translateY(-50%);
            }
            &::after {
                font-size: 1.1rem;
                margin-top: .7rem;
            }
        }
        .pc-recruit-header__logo {
            width: 22rem;
            img {
                width: 4.4rem;
            }
            .pc-recruit-header__logo-text {
                font-size: 1rem;
                margin-bottom: 0;
            }
            .pc-recruit-header__logo-name {
                font-size: 1rem;
            }
            .pc-recruit-header__logo-site {
                font-size: 1rem;
                margin-top: 0.2rem;
            }
        }
        .pc-recruit-header__link a {
            font-size: 1.1rem;
            margin-bottom: 0.4rem;
        }
        .pc-recruit-header__content-link {
            font-size: 1.4rem;
        }
    }
}