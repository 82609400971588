@use "mixin" as * ;
@use "var" as * ;

html {
    font-size: 2.666666666666667vw;
    @include tab {
        font-size: 62.5%;
    }
}
body {
    font-family: $fontJp;
    line-height: 1;
    color: $black02;
    background-color: $white02;
    &.recruit-page {
        background: $gray03;
    }
    &.privacypolicy-page {
        background: $gray03;
    }
    &.contact-page {
        background: $gray03;
    }
    &.fixed {
        overflow: hidden;
    }
}
main {
    padding-top: 7rem;
    padding-bottom: 6rem;
    @include pc {
        padding-top: 0;
    }
}
.recruit-page main {
    @include pc {
        padding-top: 14rem;
    }
}
.recruit-page .recruit-top {
    @include pc {
        padding-top: 0;
    }
}
img {
    width: 100%;
    height: auto;
}
a {
    color: inherit;
}
small {
    font-size: inherit;
}
button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    img[src$=".svg"] {
        width: 100%;
    }
}

.hidden-text {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
.sp {
    &_br {
        display: inline !important;
        @include tab {
            display: none !important;
        }
    }
    &_only {
        display: block !important;
        @include tab {
            display: none !important;
        }
    }
}
.pc {
    &_br {
        display: none !important;
        @include tab {
            display: inline !important;
        }
    }
    &_only {
        display: none !important;
        @include tab {
            display: block !important;
        }
    }
}

.sc-inner {
    @include pc {
        padding-left: 30rem;
    }
}



.pager {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    @include pc {
        margin-top: 5rem;
    }
    &__item {
        margin-right: 1rem;
        font-size: 1.6rem;
        @include pc {
            margin-right: 1.5rem;
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        border-radius: 1rem;
        font-family: $fontEn;
        font-weight: 400;
        font-size: 1.4rem;
        background-color: $white;
        border: .1rem solid $gray02;
        &--active {
            background-color: $green;
            border: none;
            color: $white;
        }
    }
}

.wp-caption-text{
    font-size: 1.2rem;
    padding-top: .8rem;
    line-height: 1.2;
    color: $gray;
}

.wp-caption {
    max-width: 100%;
}