@use "../common/summarize" as * ;

.breadcrumb {
    @include inner(15);
    padding: 1.5rem 0 2rem;
    @include pc {
        padding: 1.5rem 0 4rem;
    }
    @include tab {
        width: 100%;
    }
    &__list {
        overflow-x: scroll;
        display: flex;
        @include scrollBarRemove;
    }
    &__item {
        position: relative;
        margin-right: 2rem;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            right: -1rem;
            width: .6rem;
            height: .6rem;
            border-top: .1rem solid $black02;
            border-right: .1rem solid $black02;
            transform: translateY(-50%) rotate(45deg);
        }
        &:last-of-type {
            margin-right: 0;
            &::before {
                display: none;
            }
            .breadcrumb__link {
                text-decoration: none;
                pointer-events: none;
            }
        }
    }
    &__link {
        display: block;
        white-space: nowrap;
        word-break: keep-all;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.2;
        letter-spacing: 0.04em;
        text-decoration: underline;
        @include tab {
            font-size: 1.4rem;
        }
        &:hover {
            text-decoration: none;
        }
    }
}